import { zodResolver } from '@hookform/resolvers/zod'
import { MuiTelInputInfo } from 'mui-tel-input'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import OnboardTitleTypography from './OnboardTitleTypography'
import {
  OnboardRequestContainer,
  OnboardRequestGridContainer,
  OnboardRequestInnerGridContainer,
  OnboardRequestInputLabel,
  OnboardRequestMuiTelInput,
  OnboardRequestTextField,
} from './styled/OnboardRequest.styled'
import {
  OnboardRequestMethods,
  OnboardRequestPayloadType,
} from '../../../types'
import { useAppSelector } from '../../../store'
import { selectLocateRestaurant } from '../../../store/onboardSlice'
import { selectUserInfo } from '../../../store/authSlice'
import { Box } from '@mui/material'
import { IconInfoSquareRounded } from '@tabler/icons-react'

const formSchema = z.object({
  restaurantName: z.string().min(1, { message: 'Restaurant name is required' }),
  contactName: z.string().min(1, { message: 'Contact name is required' }),
  contactEmail: z.string().email({ message: 'Invalid email address' }),
  phoneNumber: z.string().min(1, { message: 'Phone number is required' }),
  websiteLink: z.string().url({ message: 'Invalid URL' }),
})

interface OnboardRequestProps {
  key?: number
  title?: string
  subTitle?: string
  setNextButtonDisable: (disable: boolean) => void
}

const OnboardRequest = React.forwardRef<
  OnboardRequestMethods,
  OnboardRequestProps
>(function OnboardRequest(
  { title, subTitle, setNextButtonDisable },
  ref,
): JSX.Element {
  const locateRestaurant = useAppSelector(selectLocateRestaurant)
  const loggedUser = useAppSelector(selectUserInfo)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      contactEmail: loggedUser?.email,
      phoneNumber: locateRestaurant?.phone,
      websiteLink: locateRestaurant?.website,
      contactName: locateRestaurant?.contact_name,
      restaurantName: locateRestaurant?.name,
    },
  })

  useEffect(() => {
    setNextButtonDisable(!isValid)
  }, [isValid, setNextButtonDisable])

  const getPayload = async (): Promise<OnboardRequestPayloadType | {}> => {
    let payloadData: OnboardRequestPayloadType | {} = {}
    await handleSubmit((data: z.infer<typeof formSchema>): void => {
      payloadData = data
    })()
    return payloadData
  }

  React.useImperativeHandle(ref, () => ({ getPayload }))

  return (
    <>
      <OnboardTitleTypography title={title ?? ''} subTitle={subTitle ?? ''} />
      <OnboardRequestContainer>
        <OnboardRequestGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="name">
                Restaurant Name
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="name"
                placeholder="Enter details"
                {...register('restaurantName')}
                error={!!errors.restaurantName}
                helperText={
                  errors.restaurantName ? errors.restaurantName.message : ' '
                }
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="contactName">
                Contact Name
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="contactName"
                placeholder="Enter contact name"
                {...register('contactName')}
                error={!!errors.contactName}
                helperText={
                  errors.contactName ? errors.contactName.message : ' '
                }
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="email">
                Contact Email
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="email"
                placeholder="Enter contact email"
                {...register('contactEmail')}
                error={!!errors.contactEmail}
                helperText={
                  errors.contactEmail ? (
                    errors.contactEmail.message
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <IconInfoSquareRounded
                        stroke={2}
                        size={14}
                        style={{
                          marginRight: '5px',
                        }}
                      />
                      This email is used to log in to Resactly
                    </Box>
                  )
                }
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="phoneNumber">
                Phone Number
              </OnboardRequestInputLabel>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <OnboardRequestMuiTelInput
                    {...field}
                    id="phoneNumber"
                    placeholder="Enter phone number"
                    {...register('phoneNumber')}
                    error={!!errors.phoneNumber}
                    helperText={
                      errors.phoneNumber ? errors.phoneNumber.message : ' '
                    }
                    onChange={(value, info: MuiTelInputInfo) =>
                      field.onChange(value)
                    }
                  />
                )}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <OnboardRequestInputLabel htmlFor="website">
                Website Link
              </OnboardRequestInputLabel>
              <OnboardRequestTextField
                id="website"
                placeholder="Enter website link"
                {...register('websiteLink')}
                error={!!errors.websiteLink}
                helperText={
                  errors.websiteLink ? errors.websiteLink.message : ' '
                }
              />
            </div>
          </OnboardRequestInnerGridContainer>
        </OnboardRequestGridContainer>
      </OnboardRequestContainer>
    </>
  )
})

export default OnboardRequest
