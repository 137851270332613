import {
  Autocomplete,
  Box,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IconChevronDown } from '@tabler/icons-react'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  selectIsNewRestaurent,
  selectLocateRestaurant,
  selectWebsite,
  setIsNewRestaurent,
  setLocateRestaurant,
  setRestaurant,
  setWebsiteUrl,
} from '../../../store/onboardSlice'
import {
  fetchOnboardRestaurants,
  selectIsLoading,
  selectOnboardRestaurants,
} from '../../../store/restaurantSlice'
import { OnboardRestaurantMethods, Restaurant } from '../../../types'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { debugLog } from '../../../utils/log-helper'
import OnboardTitleTypography from './OnboardTitleTypography'
import {
  OnboardLocateYourRestaurantContainer,
  OnboardLocateYourRestaurantInputLabel,
} from './styled/OnboardLocateYourRestaurant.styled'
import { OnboardRequestTextField } from './styled/OnboardRequest.styled'
import { useLocation } from 'react-router'

interface OnboardRestaurantProps {
  title?: string
  subTitle?: string
  setNextButtonDisable: (boolean) => void
}

interface OnboardNewRestaurant {
  id: number
  name: string
  website?: string
}

const OnboardLocateYourRestaurant = React.forwardRef<
  OnboardRestaurantMethods,
  OnboardRestaurantProps
>(function OnboardRestaurant(
  { title, subTitle, setNextButtonDisable },
  ref,
): JSX.Element {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [analytics, isAnalyticsReady] = useRudderStackAnalytics()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const restaurants = useAppSelector(selectOnboardRestaurants)
  const isNewRestaurent = useAppSelector(selectIsNewRestaurent)
  const locateRestaurant = useAppSelector(selectLocateRestaurant)
  const selectedWebsite = useAppSelector(selectWebsite)
  const isLoadingRestaurant = useAppSelector(selectIsLoading)

  const [website, setWebsite] = React.useState(selectedWebsite ?? '')
  const [websiteError, setWebsiteError] = React.useState<boolean>(false)
  const location = useLocation()
  const [restaurantAC, setRestaurantAC] = React.useState<
    Restaurant | undefined | OnboardNewRestaurant
  >(isNewRestaurent ? { name: 'Add New Restaurant', id: 0 } : locateRestaurant)

  React.useEffect(() => {
    if (restaurantAC && 'id' in restaurantAC && restaurantAC?.id === 0) {
      setNextButtonDisable(false)
      setWebsite('')
    } else if (
      restaurantAC !== undefined &&
      website.length > 0 &&
      website === restaurantAC?.website
    ) {
      setNextButtonDisable(false)
    } else setNextButtonDisable(true)
  }, [restaurantAC, website])

  const augmentedOptions = React.useMemo(() => {
    return [{ name: 'Add New Restaurant', id: 0 }, ...restaurants]
  }, [restaurants])

  React.useEffect(() => {
    if (analytics && isAnalyticsReady) {
      analytics?.page(
        'PAGE_OPENED',
        {
          path: '/find-restaurant',
          referrer: 'https://www.resactly.com/choose-your-role',
          search: '',
          title: 'Resactly Onboarding - Find Restaurant',
          url: 'https://www.resactly.com/find-restaurant',
          startTime: new Date().toISOString(),
          category: 'FindRestaurant',
          name: 'FindRestaurant Viewed',
        },
        () => {
          debugLog('page call')
        },
      )
    }
  }, [analytics, isAnalyticsReady])

  React.useEffect(() => {
    return () => {
      analytics?.page(
        'PAGE_CLOSED',
        {
          category: 'FindRestaurant',
          name: 'FindRestaurant Viewed',
          path: '/find-restaurant',
          referrer: 'https://www.resactly.com/choose-your-role',
          search: '',
          title: 'Resactly Onboarding - Find Restaurant',
          url: 'https://www.resactly.com/find-restaurant',
          endTime: new Date().toISOString(),
        },
        () => {
          debugLog('page call')
        },
      )
    }
  }, [location])

  React.useEffect(() => {
    dispatch(fetchOnboardRestaurants()).catch(() => {
      debugLog('error in fetching restaurants')
    })
  }, [dispatch])

  const getPayload = (): void => {
    if (restaurantAC && 'id' in restaurantAC && restaurantAC?.id === 0) {
      dispatch(setRestaurant(undefined))
      dispatch(setLocateRestaurant(undefined))
      dispatch(setIsNewRestaurent(true))
      dispatch(setWebsiteUrl(''))
    } else if (
      restaurantAC !== undefined &&
      website.length > 0 &&
      !('id' in restaurantAC)
    ) {
      let cleansed = website.replace('https://', '')
      cleansed = cleansed.replace('http://', '')
      if (cleansed.indexOf('/') > 0) {
        cleansed = cleansed.substring(0, cleansed.indexOf('/'))
      }

      dispatch(setWebsiteUrl(website))
      dispatch(setIsNewRestaurent(false))
      dispatch(setLocateRestaurant(restaurantAC))
      dispatch(
        setRestaurant(
          restaurants.find(
            (restaurant) =>
              restaurant.website?.includes(cleansed) &&
              restaurant.name === restaurantAC.name,
          ),
        ),
      )
    } else {
      dispatch(setRestaurant(undefined))
    }
  }

  React.useImperativeHandle(ref, () => ({ getPayload }))

  const handleAutocompleteChange = (
    event: any,
    newRestaurant: Restaurant | null | { name: string; id: number },
  ): void => {
    if (newRestaurant && 'id' in newRestaurant && newRestaurant.id === 0) {
      setRestaurantAC({ name: 'Add New Restaurant', id: 0 })
      setNextButtonDisable(false)
    } else {
      setRestaurantAC(newRestaurant ?? undefined)
      setNextButtonDisable(!newRestaurant)
    }
  }

  const onChangeWebsite = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setWebsite(event.target.value)
    if (event.target.value === restaurantAC?.website) setWebsiteError(false)
    else setWebsiteError(true)
  }

  const getOptionKey = (
    option: Restaurant | { name: string; id: number },
  ): string =>
    'restaurantID' in option
      ? `${option.restaurantID}-${option.name ?? ''}-${option.website ?? ''}`
      : `${option.id}-${option.name ?? ''}`

  return (
    <div>
      <OnboardTitleTypography title={title ?? ''} subTitle={subTitle ?? ''} />

      <OnboardLocateYourRestaurantContainer>
        <div>
          <OnboardLocateYourRestaurantInputLabel htmlFor="restaurant">
            Select your Restaurant
          </OnboardLocateYourRestaurantInputLabel>
          <Autocomplete
            size="small"
            fullWidth
            autoHighlight
            PaperComponent={(props) => (
              <Paper
                {...props}
                sx={{
                  fontSize: '14px !important',
                  fontWeight: `400 !important`,
                }}
              />
            )}
            id="restaurant"
            loading={isLoadingRestaurant}
            sx={{
              width: isSmallScreen ? 'calc(100vw - 32px)' : '400px',
              '& .MuiOutlinedInput-root': {
                fontSize: '16px',
                '& fieldset': {
                  borderColor: theme.palette.secondary[300],
                  borderRadius: '8px',
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.secondary[300],
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.secondary[300],
                },
              },
              '& .MuiPopper-root': {
                borderColor: 'red',
              },
            }}
            value={restaurantAC}
            onChange={handleAutocompleteChange}
            options={augmentedOptions}
            popupIcon={
              <IconChevronDown
                stroke={2}
                size={22}
                style={{ color: theme.palette.grey[300] }}
              />
            }
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
                key={getOptionKey(option)}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select option"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '16px',
                    fontWeight: 500,
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </div>
        <OnboardLocateYourRestaurantContainer>
          <OnboardLocateYourRestaurantInputLabel>
            Website Link
          </OnboardLocateYourRestaurantInputLabel>
          <OnboardRequestTextField
            id="website"
            placeholder="Website"
            value={website}
            disabled={
              restaurantAC && 'id' in restaurantAC && restaurantAC?.id === 0
            }
            onChange={onChangeWebsite}
            error={websiteError}
            helperText={
              websiteError
                ? `Your website link doesn't match with the restaurant data`
                : ''
            }
          />
        </OnboardLocateYourRestaurantContainer>
      </OnboardLocateYourRestaurantContainer>
    </div>
  )
})

export default OnboardLocateYourRestaurant
